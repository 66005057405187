.hamburger-btn {
  display: none;
  background: transparent;
  border: none;
  outline: none;
  height: 21px;
  width: 32px;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  cursor: pointer;
}

.hamburger-btn .bar {
  display: block;
  height: 3px;
  width: 30px;
  max-width: 100%;
  background: #fff;
  border-radius: 10px;
  transition: 0.3s;
}

.hamburger-btn.active .bar:nth-of-type(1) {
  transform: translateY(9px) rotate(135deg);
}

.hamburger-btn.active .bar:nth-of-type(2) {
  transform: scale(0);
}

.hamburger-btn.active .bar:nth-of-type(3) {
  transform: translateY(-9px) rotate(-135deg);
}

@media (max-width: 750px) {
  .hamburger-btn {
    display: flex;
  }
}

@media (max-width: 340px) {
  .hamburger-btn {
    position: initial;
  }
}
