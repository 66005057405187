.image-box {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  aspect-ratio: 2/1;
  transition: 0.3s transform ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-box:hover {
  transform: scale(1.01);
}
