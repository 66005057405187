/* fonts */
/* Expletus Sans */
@import url("https://fonts.googleapis.com/css2?family=Expletus+Sans:ital,wght@0,400;0,600;0,700;1,400&display=swap");

/* Roboto */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

/* RESET */
*,
*::before,
*::after {
  margin: 0;
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
p,
dl,
dd,
figure {
  margin: 0;
}

ul[class],
ol[class] {
  list-style: none;
  padding: 0;
}

input,
button,
textarea,
select {
  font: inherit;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

.default-link {
  text-decoration: none;
}

:root {
  font-size: 1.125rem;
}

html {
  scroll-behavior: smooth;
}
/* RESET END */

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
