.image-text__container {
  text-align: center;
}

.image-text__container img {
  display: inline-block;
  width: 20vw;
  margin: 0;
}

.image-text__content .text {
  font-weight: 600;
  font-size: 5vw;
}

.image-text__content .text_sub {
  font-weight: 200;
  font-size: 5vw;
  color: rgb(187, 184, 184);
}

.image-text__content button {
  font-family: "Expletus Sans";
  font-size: 1.3vw;
  font-weight: 800;
  background: #fff;
  color: #000;
  padding: 0.5vw 4vw;
  border: 0;
  outline: 0;
  border-radius: 300px;
  cursor: pointer;
}

.image-text__content > * + * {
  margin-top: 0.5em;
}

@media (min-width: 750px) {
  .image-text__content .text {
    font-size: 1.4vw;
  }
}

@media (max-width: 750px) {
  .image-text__container img {
    width: 50vw;
  }

  .image-text__content button {
    font-size: 5vw;
    padding: 2vw 18vw;
  }
}
