.main {
  overflow: hidden;
}

.h1-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.desktop-h1 {
  font-family: "Expletus Sans";
  text-align: center;
  font-size: 1.8vw;
  width: 24ch;
  position: absolute;
  bottom: 25%;
  left: 50%;
  transform: translate(-50%, 75%);
}

.first-custom-container {
  display: flex;
  justify-content: space-between;
}

.first-custom-container h2 {
  font-family: "Expletus Sans";
  margin-right: 3vw;
  font-size: 1.25vw;
}

.first-custom-container p {
  font-size: 1vw;
  line-height: 1.3;
}

.first-custom-container + .first-custom-container {
  margin-top: 2rem;
}

.second-custom-container {
  display: flex;
  align-items: flex-start;
}

.second-custom-container + .second-custom-container {
  margin-top: 7vw;
}

.second-custom-container img {
  width: 4vw;
  margin-top: -0.9rem;
  margin-right: 1rem;
}

.second-custom-container p {
  font-size: 0.9vw;
  line-height: 1.3;
}

.levels {
  display: flex;
  justify-content: center;
  font-size: 0.9vw;
  margin: 3vw 0 2vw;
}

.levels > div > div + div {
  margin-top: 0.4vw;
}

.levels span {
  letter-spacing: 1px;
}

.provenance-record {
  color: rgb(6, 159, 230);
}

@media (max-width: 750px) {
  .first-custom-container h2 {
    font-size: 5vw;
  }

  .first-custom-container p {
    font-size: 3vw;
  }

  .second-custom-container + .second-custom-container {
    margin-top: 15vw;
  }

  .second-custom-container img {
    margin-top: -0.4rem;
    width: 10vw;
  }

  .second-custom-container p {
    font-size: 3vw;
  }

  .levels {
    font-size: 3vw;
    margin: 5vw 0;
  }
}
