.mobile-h1 {
  font-family: "Expletus Sans";
  font-size: 5vw;
  width: 24ch;
  text-align: center;
}

.first-comp-container {
  width: 80%;
}

.first-mobile-custom-container h2 {
  font-family: "Expletus Sans";
  margin-right: 3vw;
  font-size: 1.25vw;
}

.first-mobile-custom-container p {
  margin-top: 2.5vw;
  font-size: 1vw;
  line-height: 1.4;
}

.first-mobile-custom-container + .first-mobile-custom-container {
  margin-top: 8vw;
}

.row-custom-container {
  display: flex;
  scroll-margin-top: 3.07812rem;
}

.row-custom-container > * {
  width: 50%;
}

.second-custom-container {
  /* font-family: "Expletus Sans"; */
  display: flex;
  align-items: flex-start;
}

.second-custom-container + .second-custom-container {
  margin-top: 15vw;
}

.second-custom-container img {
  margin-right: 3vw;
  margin-top: -2.5vw;
  width: 13vw;
}

.second-custom-container p {
  font-size: 3.2vw;
  line-height: 1.3;
}

.levels {
  display: flex;
  justify-content: center;

  font-size: 3.2vw;
  margin: 5vw 0;
}

.levels > div > div + div {
  margin-top: 1vw;
}

.provenance-record {
  color: rgb(6, 159, 230);
}

@media (max-width: 750px) {
  .first-mobile-custom-container h2 {
    font-size: 5vw;
  }

  .first-mobile-custom-container p {
    font-size: 3vw;
  }

  .second-mobile-custom-container + .second-custom-container {
    margin-top: 15vw;
  }

  .second-mobile-custom-container img {
    margin-top: -0.4rem;
    width: 10vw;
  }

  .second-mobile-custom-container p {
    font-size: 3vw;
  }
}
