.footer {
  background: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3em 10em;
}

.footerLogo {
  max-width: 100px;
}

.formInputContainer {
  background: blue;
  position: relative;
  width: 300px;
  height: 40px;
  margin-top: 1em;
  display: flex;
}

.formInputContainer input {
  border: none;
  height: 100%;
  padding: 0 0.5em;
}

.formInputContainer input:focus {
  outline: none;
}

.formInputContainer button {
  background: rgb(110, 110, 110);
  outline: none;
  border: none;
  height: 100%;
  cursor: pointer;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formInputContainer button:hover {
  background: rgb(53, 53, 53);
}

.socialAndConditions p {
  text-align: right;
}

.socialAndConditions ul > * {
  margin-left: auto;
}

.topSpacer {
  margin-top: 1em;
}

p.conditions {
  margin-top: 0.1rem;
  font-size: 15px;
}

.socialAndConditions a {
  text-align: right;
  display: inline-block;
  width: 100%;
  color: rgb(0, 126, 211);
  text-decoration: none;
}

@media (max-width: 1050px) {
  .footer {
    flex-direction: column;
    padding: 4em;
  }

  .footer > * {
    margin-top: 5vw;
  }

  .socialAndConditions ul > * {
    margin-left: auto;
    margin-right: auto;
  }

  .socialAndConditions p,
  .socialAndConditions a {
    text-align: center;
  }
}
