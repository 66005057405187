.social-list {
  display: flex;
}

.social-list li + li {
  margin-left: 1.5rem;
}

.social-list img {
  max-width: 1.4rem;
}

@media (max-width: 750px) {
  .social-list {
    display: none;
  }
}
