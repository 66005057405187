.image-box {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  aspect-ratio: 1/1;
  height: 33vw;

  /* transition: 0.3s transform ease; */
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 750px) {
  .image-box {
    height: 100vw;
  }
}

/* .image-box:hover {
  transform: scale(1.02);
} */
