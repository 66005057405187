.header {
  background: #000;
  display: flex;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  justify-content: space-between;
  align-items: center;
  padding: 0.25em 1.5em;
  z-index: 50;
}

.logoImg {
  max-width: 3rem;
  position: relative;
  z-index: 10;
}

@media (max-width: 750px) {
  .header {
    padding: 0.55em 0.75em;
  }
}
