.comp-container {
  align-self: stretch;
  width: 80%;
  position: relative;
}

.header-container {
  position: absolute;
  height: 50%;
  width: 100%;
}

.header-container img {
  width: 20vw;
  position: absolute;
  top: 0;
  right: 0%;
}

.header-text {
  font-family: "Expletus Sans";
  position: absolute;
  bottom: 0;
  left: 7%;
}

.header-text h2 {
  font-size: 1vw;
  width: 17ch;
}

.header-text p {
  font-size: 0.85vw;
  font-style: italic;
}

.body-text {
  font-size: 0.8vw;
  line-height: 1.3;
  position: absolute;
  top: 55%;
  left: 7%;
}

.body-text p {
  width: 51ch;
}

@media (max-width: 750px) {
  .comp-container {
    width: 95%;
  }
  .header-container img {
    width: 60vw;
    top: 5%;
  }

  .header-text h2 {
    font-size: 4vw;
  }

  .header-text p {
    font-size: 3.5vw;
  }

  .body-text {
    font-size: 2.9vw;
  }
}
