.navigation-list {
  display: flex;
}

.navigation-list li + li {
  margin-left: 1.25rem;
}

.navigation-list > li > a {
  font-family: "Expletus Sans";
  text-decoration: none;
  color: #fff;
}

.navigation-list > li > a:hover {
  color: grey;
}

@media (max-width: 750px) {
  .navigation {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: #000;
    top: -100vh;
    left: 0;
    transition: transform 0.3s ease;
    overflow-y: scroll;
  }

  .navigation-list {
    margin-top: 6rem;
    flex-direction: column;
  }

  .navigation-list li {
    text-align: center;
    opacity: 0;
    transition: 0.2s ease 0.3s;
  }

  .navigation-list li a {
    font-size: 1.25rem;
  }

  .navigation-list li + li {
    margin: 0;
    margin-top: 2rem;
  }

  .navigation.active {
    transform: translateY(100%);
  }

  .navigation.active .navigation-list li {
    opacity: 1;
  }
}
