.flex {
  display: flex;
  scroll-margin-top: 2.47916rem;
}

.flex > * {
  flex: 1 1 auto;
  width: 100%;
}

@media (max-width: 750px) {
  .flex {
    flex-direction: column;
    height: 100vw;
    scroll-margin-top: 3.07812rem;
  }
}
